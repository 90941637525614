// CandidatesPage.js

//component Imports
import Navbar from "../sections/NavBar/Navbar";
import TestimonialsSection from "../sections/Testimonials/TestimonialsSection";
import Footer from "../sections/Footer/Footer";
import HeroBanner from "../sections/HeroBanner/HeroBanner";

//Employers Section Imports
import CustomCard from "../sections/Candidates/CandidatesSection";

// Asset Imports
import candidatesBannerImage from "../assets/CandidatesHeroBanner.jpg";

const CandidatesPage = () => {
  return (
    <>
      <Navbar />
      <HeroBanner
        page={"Candidates"}
        backgroundImage={candidatesBannerImage}
        heading="A company's employees are its greatest asset, and your people are your product."
        headingStyle={{
          color: "black",
        }}
        buttonText="Meet Your Next Hire"
        buttonLink="/contact"
      />
      <CustomCard />
      <TestimonialsSection />
      <Footer />
    </>
  );
};

export default CandidatesPage;
