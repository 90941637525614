// HeroBanner.js
import { useEffect } from "react";
import {
  Paper,
  Typography,
  Breadcrumbs,
  Link as MuiLink,
  IconButton,
  useTheme,
  Box,
  Fade,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "../../styles/HeroBanner.css";

//Component Imports
import ContainedButton from "../../components/Buttons/ContainedButton";
import GradientDivider from "../../components/GradientDivider/GradientDivider";

const keyframes = `
@keyframes fallAndFade {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}`;

const HeroBanner = ({
  page,
  backgroundImage,
  quote,
  quoteStyle,
  heading,
  headingStyle,
  subtitle,
  subtitleStyle,
  buttonText,
  buttonLink,
  nextSectionRef,
}) => {
  const theme = useTheme();

  useEffect(() => {
    document.title = `${page} | GB Talent Solutions`;
  }, [page]);

  const scrollToNextSection = () => {
    if (nextSectionRef && nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <style>{keyframes}</style>
      <Paper
        className="hero-banner large"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: theme.spacing(2),
          [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1),
          },
        }}
        elevation={0}
      >
        <Fade in={true} timeout={3000}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "60rem",
              mx: "auto",
              textAlign: "center",
              p: { xs: 1, sm: 2, md: 3 },
              animation: "fallAndFade 1s ease forwards",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {page !== "home" && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: theme.spacing(2),
                }}
              >
                <Breadcrumbs aria-label="breadcrumb">
                  <MuiLink
                    component={Link}
                    sx={{
                      color: theme.palette.primary.light,
                      textDecoration: "none",
                    }}
                    to="/"
                    variant="h6"
                  >
                    Home
                  </MuiLink>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.primary.light,
                      textDecoration: "none",
                    }}
                  >
                    {page}
                  </Typography>
                </Breadcrumbs>
              </Box>
            )}
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                ...quoteStyle,
                textAlign: "center",
                padding: "0.5rem",
              }}
            >
              {quote}
            </Typography>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                ...headingStyle,
                textAlign: "center",
                padding: "0.5rem",
              }}
            >
              {heading}
            </Typography>
            <Typography
              variant="subtitle1"
              className="lead"
              style={{
                ...subtitleStyle,
                textAlign: "center",
                padding: "0.5rem",
              }}
            >
              {subtitle}
            </Typography>
            {buttonText && (
              <ContainedButton
                text={buttonText}
                link={buttonLink}
                textColor={theme.palette.primary.contrastText}
                bgColor={`linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.blue.main} 90%)`}
                size="large"
              />
            )}
          </Box>
        </Fade>
        {page === "home" && (
          <IconButton
            aria-label="scroll down"
            size="large"
            onClick={scrollToNextSection}
            sx={{
              position: "absolute",
              transform: "translateX(-50%)",
              animation: "bounce 2s infinite",
              border: "2px solid white",
              borderRadius: "50%",
              padding: "0.5625rem",
              width: theme.spacing(7),
              height: theme.spacing(7),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              left: {
                xs: "45%",
                sm: "47%",
                md: "47%",
                lg: "48.5%",
                xl: "48.5%",
              },
              bottom: {
                xs: theme.spacing(2),
                sm: theme.spacing(4),
                md: theme.spacing(6),
                lg: theme.spacing(6),
                xl: theme.spacing(8),
              },
              [theme.breakpoints.down("sm")]: {
                width: theme.spacing(5),
                height: theme.spacing(5),
              },
            }}
          >
            <ArrowDownwardIcon style={{ color: "white" }} />
          </IconButton>
        )}
      </Paper>
      <GradientDivider sx={{ position: "relative", bottom: 1, zIndex: 1 }} />
    </>
  );
};

HeroBanner.propTypes = {
  page: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  quote: PropTypes.string,
  quoteStyle: PropTypes.object,
  heading: PropTypes.string.isRequired,
  headingStyle: PropTypes.object,
  subtitle: PropTypes.string,
  subtitleStyle: PropTypes.object,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default HeroBanner;
