import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
  Slide,
} from "@mui/material";
import PropTypes from "prop-types";
import ToggleButton from "../Buttons/ToggleButton";
import TextButton from "../Buttons/TextButton";

//Icon Imports
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

function TeamMemberCard({
  name,
  title,
  description,
  imageUrl,
  tel,
  email,
  linkedInProfile,
  toggleSection,
  toggleContent,
  reverseLayout,
}) {
  const [checked, setChecked] = React.useState(false);
  const ref = React.useRef(null);

  //Directions
  const imgAnimationDir = reverseLayout ? "left" : "right";
  const txtAnimationDir = reverseLayout ? "right" : "left";

  const theme = useTheme();

  React.useEffect(() => {
    const currentRef = ref.current; // Capture the current value at the start of the effect

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setChecked(true);
          observer.unobserve(currentRef); // Use captured ref value
        }
      },
      {
        threshold: 0.6,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use captured ref value in cleanup
      }
    };
  }, []); // No dependencies are necessary here as ref and setChecked do not change

  const linkBoxStyles = {
    margin: theme.spacing(1, 0),
    padding: 0,
    display: "flex",
    alignItems: "center",
  };

  return (
    <Card
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: reverseLayout ? "row-reverse" : "row",
        },
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "100%",
        boxShadow: "none",
        bgcolor: theme.palette.grey[100],
        m: 1,
        p: { xs: 2, md: 4 },
      }}
    >
      <Slide
        direction={imgAnimationDir}
        in={checked}
        mountOnEnter
        unmountOnExit
        {...(checked ? { timeout: 1500 } : {})}
      >
        <Box
          sx={{
            flex: { xs: 0, md: 1 },
            maxWidth: { xs: "75%", md: "50%" },
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: "100%",
              height: "auto",
            }}
            image={imageUrl}
            alt={`Image of ${name}`}
          />
        </Box>
      </Slide>
      <Slide
        direction={txtAnimationDir}
        in={checked}
        mountOnEnter
        unmountOnExit
        {...(checked ? { timeout: 1500 } : {})}
      >
        <Box>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { md: reverseLayout ? "flex-end" : "flex-start" },
              maxWidth: { md: 500 },
              m: 2,
              flex: "1",
            }}
          >
            <Typography variant="h5">{name}</Typography>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              {title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, whiteSpace: "pre-line" }}>
              {description}
            </Typography>
            {toggleSection && toggleContent && (
              <ToggleButton
                size="small"
                collapseText={toggleSection}
                expandText={toggleSection}
                sx={{
                  padding: `${theme.spacing(0)} !important`,
                  mb: theme.spacing(2),
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ mb: 2, whiteSpace: "pre-line" }}
                >
                  {toggleContent}
                </Typography>
              </ToggleButton>
            )}
            {email && (
              <TextButton
                size="small"
                text={`Email: ${email}`}
                link={`mailto:${email}`}
                textColor={theme.palette.primary.dark}
                sx={linkBoxStyles}
                startIcon={<EmailIcon />}
              />
            )}
            {tel && (
              <TextButton
                size="small"
                text={tel}
                link={`tel:${tel}`}
                textColor={theme.palette.primary.dark}
                sx={linkBoxStyles}
                startIcon={<CallIcon />}
              />
            )}

            {linkedInProfile && (
              <TextButton
                size="small"
                text="LinkednIn"
                link={linkedInProfile}
                textColor={theme.palette.primary.dark}
                sx={linkBoxStyles}
                startIcon={<LinkedInIcon />}
              />
            )}
          </CardContent>
        </Box>
      </Slide>
    </Card>
  );
}

TeamMemberCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  tel: PropTypes.string,
  email: PropTypes.string,
  linkedInProfile: PropTypes.string,
  toggleSection: PropTypes.string,
  toggleContent: PropTypes.string,
  reverseLayout: PropTypes.bool.isRequired,
};

export default TeamMemberCard;
