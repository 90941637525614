// ToggleButton.js
import { useState, useRef } from "react";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";
import BaseButton from "./BaseButton";

//Icons Import
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ToggleButton = ({
  children,
  size,
  collapseText,
  expandText,
  expandIcon,
  collapseIcon,
  textColor,
  hoverColor,
  sx,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);
  const theme = useTheme();

  const collapse_text = collapseText || "Read Less";
  const CollapseIcon = collapseIcon || RemoveIcon;
  const ExpandIcon = expandIcon || AddIcon;
  const expand_text = expandText || "Read More";

  const handleToggle = () => {
    if (isExpanded) {
      contentRef.current.style.maxHeight =
        contentRef.current.scrollHeight + "px";
      setTimeout(() => (contentRef.current.style.maxHeight = "0"), 0);
    } else {
      contentRef.current.style.maxHeight =
        contentRef.current.scrollHeight + "px";
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div
        ref={contentRef}
        style={{
          maxHeight: "0",
          overflow: "hidden",
          transition: "max-height 1s ease",
        }}
      >
        <div>{children}</div>
      </div>
      <BaseButton
        onClick={handleToggle}
        textColor={
          isExpanded
            ? hoverColor || theme.palette.primary.main
            : textColor || theme.palette.primary.dark
        }
        size={size}
        endIcon={isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        disableRipple
        sx={{
          textDecoration: "none",
          "&:hover": {
            color: hoverColor || theme.palette.primary.main,
            transition: "color 0.9s ease",
            background: "transparent",
          },
          ...sx,
        }}
      >
        {isExpanded ? collapse_text : expand_text}
      </BaseButton>
    </>
  );
};

ToggleButton.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  collapseText: PropTypes.string,
  expandText: PropTypes.string,
  expandIcon: PropTypes.elementType,
  collapseIcon: PropTypes.elementType,
  textColor: PropTypes.string,
  hoverColor: PropTypes.string,
  sx: PropTypes.object,
};

export default ToggleButton;
