import { Box } from "@mui/material";
import PropTypes from "prop-types";

const hexToRgba = (hex, alpha = 1) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const GradientDivider = ({ sx }) => {
  const alphaValue = 0.6;

  return (
    <Box
      sx={{
        width: "100%",
        height: "25px",
        backgroundImage: `linear-gradient(90deg,
          ${hexToRgba("#4B8D97ff", alphaValue)} 0%,
          ${hexToRgba("#427E9Eff", alphaValue)} 14%,
          ${hexToRgba("#4574A4ff", alphaValue)} 28%,
          ${hexToRgba("#3C6DBCff", alphaValue)} 42%,
          ${hexToRgba("#456AC1ff", alphaValue)} 57%,
          ${hexToRgba("#5A6FC6ff", alphaValue)} 71%,
          ${hexToRgba("#5453A2ff", alphaValue)} 85%,
          ${hexToRgba("#7162A7ff", alphaValue)} 100%)`,
        marginTop: "-10px",
        ...sx,
      }}
    />
  );
};

GradientDivider.propTypes = {
  sx: PropTypes.object,
};

export default GradientDivider;
