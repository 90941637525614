//ContactFormSection.js
import { Box, Typography, Paper, useTheme } from "@mui/material";
import ContactForm from "../../components/ContactForm/ContactForm";

const ContactFormSection = ({ onSendEmail }) => {
  const theme = useTheme();

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          mx: "auto",
          my: { xs: 2, md: 4 },
          padding: { xs: 2, md: 4 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        elevation={0}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            m: theme.spacing(4),
            p: theme.spacing(4),
          }}
        >
          Let's Connect!
        </Typography>
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: theme.spacing(2),
            p: theme.spacing(2),
            bgcolor: theme.palette.grey[300],
          }}
        >
          <ContactForm onSendEmail={onSendEmail} />
        </Box>
      </Paper>
    </>
  );
};

export default ContactFormSection;
