//EmployersList.js
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const titleVariants = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const descriptionVariants = {
  hidden: { x: 100, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const EmployersList = ({ title, listItems, description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      p={4}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{ textAlign: isMobile ? "center" : "right" }}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={titleVariants}
            transition={{ duration: 1 }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 700,
                paddingTop: theme.spacing(2),
              }}
            >
              {title}
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12} sm={9}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={descriptionVariants}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {description && (
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  textAlign: "left",
                  margin: theme.spacing(2),
                  fontWeight: 600,
                  color: theme.palette.grey[800],
                }}
              >
                {description}
              </Typography>
            )}
            <List
              sx={{
                listStyleType: "disc",
                padding: 0,
                marginLeft: theme.spacing(3),
                fontSize: "1.5rem",
                color: theme.palette.grey[800],
              }}
            >
              {listItems.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "list-item", color: theme.palette.grey[800] }}
                >
                  <Typography variant="subtitle2">{item}</Typography>
                </ListItem>
              ))}
            </List>
          </motion.div>
        </Grid>
      </Grid>
    </Box>
  );
};

EmployersList.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EmployersList;
