// EmployersPage.js
//Component Imports
import Navbar from "../sections/NavBar/Navbar";
import TestimonialsSection from "../sections/Testimonials/TestimonialsSection";
import Footer from "../sections/Footer/Footer";
import HeroBanner from "../sections/HeroBanner/HeroBanner";

//Employers Section Imports
import EmployersIntro from "../sections/Employers/EmployersIntro";
import EmployersCards from "../sections/Employers/EmployersCards";
import EmployersOutro from "../sections/Employers/EmployersOutro";

// Asset Imports
import employersBannerImage from "../assets/EmployersHeroBanner.jpg";

const EmployersPage = () => {
  return (
    <>
      <Navbar />
      <HeroBanner
        page={"Employers"}
        backgroundImage={employersBannerImage}
        heading="A company's employees are its greatest asset, and your people are your product."
        headingStyle={{
          color: "white",
        }}
        buttonText="Meet Your Next Hire"
        buttonLink="/contact"
      />
      <EmployersIntro />
      <EmployersCards />
      <EmployersOutro />
      <TestimonialsSection />
      <Footer />
    </>
  );
};

export default EmployersPage;
