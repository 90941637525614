import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
const ResumeTips = () => {
  return (
    <Container maxWidth="md">
      <Paper elevation={0} style={{ padding: "20px", marginTop: "20px" }}>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <Typography variant="h5" component="h2" gutterBottom>
              Creating An Effective & Engaging New Resume, The GB Talent Way!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph>
              Resume formats have changed a lot in the last 10 (even 5) years,
              and that’s why many people searching for new jobs find updating
              their current resume to be a daunting task. Hiring managers all
              have different preferences and expectations of a well-crafted
              resume, so how do you build one that resonates with them, is
              attractive and effective and won’t get shoved to the bottom of the
              pile?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>
              We’ve got your back! Here’s some helpful advice about how to
              create a resume that will get the attention of the people you need
              to target.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography paragraph>
              1. <strong>Position Title.</strong> Under your name, place the
              position title that aligns with the position you are applying for.
              This lets future employers know immediately that you consider
              yourself to be the type of employee that they’re looking for.
            </Typography>

            <Typography paragraph>
              2. <strong>Profile Summary.</strong> Your profile summary should
              be short and sweet. As in, one or two sentences that describe who
              you are and what you’re looking for without taking more than a
              couple of seconds to read.
            </Typography>

            <Typography paragraph>
              3. <strong>Work Experience.</strong> The company, position and
              dates of employment should be clear and easy to read. Give your
              completed resume to a family member or friend to review and ensure
              they have no confusion about where, when and where you worked.
            </Typography>
            <Typography paragraph>
              4. <strong>Workplace Descriptions.</strong> A short sentence
              describing what the companies you’ve worked with do is a good
              idea. This is vital when your past employer may not be well-known
              by everyone in the job market.
            </Typography>

            <Typography paragraph>
              5. <strong>Description of Duties.</strong> The bulk of content
              under each position you held should be accomplishment focused.
              There is an understandable tendency to list every responsibility
              you held underneath each position, but this is actually
              ineffective. Why? Because employers will typically already
              understand all the responsibilities of a job when they read the
              title and know what type of company it is. Also, your resume is
              not meant to list every single activity you have performed
              throughout your career, it is meant to broadly showcase your
              accomplishments and successes so that employers will want to
              engage with you and find out more by inviting you to an interview.
            </Typography>

            <Typography paragraph>
              6. <strong>Use a Free Template.</strong> There are hundreds of
              attractive, well-organized, free templates online that will help
              to make your resume great. If you have trouble fitting your
              information into one or two pages, use a resume template that
              makes use of a column format. This allows your education and
              skills to be placed alongside your experience, rather than having
              to decide what should come first.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph>
              • <Link href="/download">Download free resume templates</Link>
            </Typography>
          </Grid>

          <Divider style={{ margin: "20px 0" }} />
          <Grid item xs={12}>
            <Typography paragraph>
              <strong>
                Need a bit more help? GB Talent can create the perfect resume
                for you.
              </strong>{" "}
              As professionals who have looked at countless resumes ourselves,
              we know what it takes to put one together that will be
              eye-catching and have employers eager to meet you. We will first
              conduct a video meeting/interview to get to know you and find out
              about exactly what you’re looking for. Then we will target your
              skills and highlight your experiences to craft a professional
              resume that is guaranteed to get you noticed by the employers you
              are targeting in your search.
            </Typography>

            <Typography paragraph>
              If you are interested in having us craft a custom resume for you,
              we can do that.{" "}
              <strong>Cost for this service is $150 per resume.</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph>
              •{" "}
              <Link href="/custom-resume">
                Get your new custom GB Talent resume
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ResumeTips;
