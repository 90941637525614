//Footer.js
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Link as MuiLink,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";

//Component Imports
import TextButton from "../../components/Buttons/TextButton";
import GBLogo from "../../components/Logo/GBLogo";
import IconWithTooltip from "../../components/IconWithToolTip/IconWithToolTip";

//Icon Imports
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sitemapLinks = [
    { name: "Home", path: "/" },
    { name: "Employers", path: "/employers" },
    { name: "Candidates", path: "/candidates" },
    // { name: "Blog", path: "/blog" },
    { name: "Contact", path: "/contact" },
  ];

  const logoLinkStyle = {
    transition: "color 0.9s ease",
    "&:hover": {
      color: "common.white",
    },
  };

  return (
    <Box sx={{ bgcolor: "#171717", px: "2rem", py: "5rem" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="left"
        spacing={4}
      >
        {/* Logo section */}
        <Grid item xs={12} sm={4} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: isMobile ? "center" : "flex-start",
              mb: 2,
            }}
          >
            <GBLogo
              color={theme.palette.primary.main}
              sx={logoLinkStyle}
              effectiveSize={isMobile ? "small" : "large"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: isMobile ? "center" : "flex-start",
              }}
            >
              <IconWithTooltip
                icon={LinkedInIcon}
                color="primary"
                tooltipText="LinkedIn"
                size={isMobile ? "small" : "large"}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/graeme-burke-rpr-256086136/",
                    "_blank"
                  )
                }
                sx={logoLinkStyle}
              />
            </Box>
          </Box>
        </Grid>

        {/* Contact section */}
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            textAlign: isMobile ? "center" : "left",
            ...(!isMobile && {
              left: "50%",
              transform: "translateX(25%)",
            }),
          }}
        >
          <Typography
            variant="subtitle2"
            gutterBottom
            color={theme.palette.grey.light}
          >
            CONTACT
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color={theme.palette.grey.medium}>
                Phone:
              </Typography>
              <TextButton
                text="204-960-5403"
                link="tel:204-960-5403"
                size="small"
                textColor={theme.palette.primary.main}
                sx={{ padding: 0.5 }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "left", gap: 0.5, mt: 1 }}>
              <TextButton
                text="gburke@gbtalent.ca"
                link="mailto:gburke@gbtalent.ca"
                size="small"
                textColor={theme.palette.primary.main}
                sx={{ padding: 0 }}
                startIcon={<EmailIcon />}
              />
            </Box>
          </Box>
        </Grid>

        {/* Sitemap section */}
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            textAlign: isMobile ? "center" : "left",
            display: "flex",
            flexDirection: "column",
            alignItems: isMobile ? "center" : "flex-end",
            paddingRight: isMobile ? theme.spacing(0) : theme.spacing(4),
          }}
        >
          <nav>
            {sitemapLinks.map((link) => (
              <TextButton
                text={link.name}
                link={link.path}
                size="small"
                textColor={theme.palette.primary.main}
                sx={{
                  padding: 0.5,
                  display: "block",
                }}
              />
            ))}
          </nav>
        </Grid>
      </Grid>

      {/* Footer bottom text */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "inset 0px 0.5px 0px 0px" + theme.palette.grey.dark,
          pt: 2,
          mt: 3,
          textAlign: "left",
        }}
      >
        <Typography variant="caption" color={theme.palette.grey.medium}>
          <MuiLink
            component={Link}
            to={"https://chattersphere.me/"}
            sx={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Winnipeg Website By Chattersphere
          </MuiLink>
        </Typography>
        <IconButton
          disableRipple
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          aria-label="scroll back to top"
          sx={{
            backgroundColor: "transparent",
            color: theme.palette.grey.medium,
            "&:hover": {
              color: "primary.main",
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
