//BlogPostSection.js
import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "long" });
  return `${day} ${month}`;
};

const BlogPostsSection = ({ posts }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "20px",
        p: "30px",
      }}
    >
      {posts.map((post) => (
        <Box
          component={Link}
          to={`/blog/${post.id}`}
          key={post.id}
          sx={{
            maxWidth: 345,
            mb: "20px",
            textDecoration: "none",
            width: "100%", // Ensure responsiveness
          }}
        >
          <Card sx={{ maxWidth: 345, width: "100%", position: "relative" }}>
            <CardMedia
              component="img"
              height="250"
              image={post.image}
              alt={post.title}
            />
            <Box
              sx={{
                position: "absolute",
                top: 16,
                left: 16,
                bgcolor: "rgba(255, 255, 255, 0.7)",
                padding: "4px 8px",
                borderRadius: "0px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -10,
                  left: -10,
                  bgcolor: "white",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  boxShadow: 1,
                  margin: "auto", // This will center the box
                  maxWidth: "fit-content", // Ensure the box fits its content
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  sx={{
                    fontSize: "0.6rem", // Adjust the font size as needed
                    textAlign: "center",
                  }}
                >
                  {formatDate(post.created_on)}
                </Typography>
              </Box>
            </Box>
          </Card>
          <Box
            sx={{
              wordWrap: "break-word",
              textAlign: "center",
              p: "8px 0",
            }}
          >
            <Typography
              sx={{
                wordWrap: "break-word",
                textAlign: "left",
                p: "8px 16px",
                height: "3.6em",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {post.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default BlogPostsSection;
