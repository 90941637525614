//EmployersCards.js
import { Box, useTheme } from "@mui/material";

//Component Imports
import EmployeesContentGrid from "../../components/EmployersPage/EmployersTextCard";

function EmployersCards() {
  const theme = useTheme();
  const contentData = [
    {
      quote: "Active Headhunting Approach",
      description: `We use market research to find the best talent available that comes with direct relevant experience in helping companies to grow in areas that are directly relevant to our client’s needs. Most importantly, we use a personal approach to informing top talent about market opportunities. As a third party, we can confidentially find out if top candidates may be open for a change and continue to assess them for fit for opportunities. We meet candidates when it is convenient for them, this is not a 9-5 job as we know that great people are often busy working during the days, but open to discussions in the evenings, on  weekends or when they are not on company time. `,
    },
    {
      quote: "Industry Leading Technology",
      description: `We thoroughly vet our candidates’ backgrounds by obtaining references from leaders within their organizations—we are great at flushing out relevant information, and we used the most advanced sourcing tools to ensure that we can connect with anyone in the world. 
      We’re experts in Boolean, which is an Internet language for searching specific requirements. We also utilize AI for candidate sourcing and storage, but at the same time we do not rely on AI as this industry needs a personalized touch to be most effective. GB Talent Solutions uses industry leading ATS and CRM systems, as well as Linkedin Corporate for full market access. Most calls are conducted through video meetings unless in-person is available. We also provide at no additional cost, reference and criminal background checks, and have professional experience with third party assessments.
      `,
    },
    {
      quote: "Networking Experts with Top Tier Referrals",
      description:
        "With a strong reputation in the market, and time spent connecting with many community partners we have many trusted alliances and are constantly receiving ongoing referrals for high-level candidates that cannot be found through job advertisements.",
    },
  ];

  const bgColor = [theme.palette.primary.light, "white"];

  return (
    <Box sx={{ width: "100%" }}>
      {contentData.map((item, index) => (
        <EmployeesContentGrid
          key={index}
          quote={item.quote}
          description={item.description}
          bgColor={bgColor[index % bgColor.length]}
        />
      ))}
    </Box>
  );
}

export default EmployersCards;
