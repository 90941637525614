// TextButton.js
import { Typography } from "@mui/material";
import BaseButton from "./BaseButton";
import PropTypes from "prop-types";

const TextButton = ({
  text,
  link,
  textColor,
  size,
  startIcon,
  sx,
  textSize,
}) => {
  return (
    <BaseButton
      component="a"
      href={link}
      textColor={textColor}
      size={size}
      startIcon={startIcon}
      sx={{
        textDecoration: "none",
        "&:hover": {
          color: "common.white",
          transition: "color 0.9s ease",
          textDecoration: "underline",
          background: "transparent",
        },
        ...sx,
      }}
    >
      {<Typography sx={{ fontSize: textSize }}>{text}</Typography>}
    </BaseButton>
  );
};

TextButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  sx: PropTypes.object,
  startIcon: PropTypes.element,
  textSize: PropTypes.string,
};

export default TextButton;
