//MeetOurTeamSection.js
import React from "react";

import { Box, Typography, useTheme, Divider, Slide } from "@mui/material";

import TeamMemberCard from "../../components/AboutPage/TeamMemberCard";

// Asset import
import teamMemberImage from "../../assets/GraemePic.jpg";

const teamMembersData = [
  {
    name: "Graeme Burke",
    title: "Managing Partner",
    description: `Graeme is well known in the tech business community, but also in many different professional sectors having worked and thrived in all spaces, from Agriculture, to Manufacturing, to Healthcare, Retail, Building Products, Technology, and many more!
        
        Bringing with him a Bachelor of commerce and a keen understanding for entrepreneurship, he has mentored countless candidates to success and helped companies to break through stagnation and even be named in the list of the fastest growing companies in North America.
        `,
    imageUrl: teamMemberImage,
    tel: "+1-204-960-5403",
    email: "gburke@gbtalent.ca",
    linkedInProfile: "https://www.linkedin.com/in/graeme-burke-rpr-256086136/",
    toggleSection: "His approach",
    toggleContent: `It all starts with effective recruitment and bringing the best of the best right to your door. Imagine your sales reps walking in the door with multi million-dollar networks, your operations leaders coming in with a bread of technical experience, all talent within your company from specialists to leaders coming into your company, already possessing the experience to hit the ground running and make an impact. 
    
    That is an effective recruiter like Graeme achieves time and time again. Graeme is also a firm believer that the right HR strategies can make a big impact. While the effective recruitment Graeme brings is transformative, retaining these employees and helping them to grow is something that your average recruiter does not take on, yet Graeme is there for his clients as an expert in their corner to provide this HR advantage. 
    `,
  },
];

function MeetOurTeamSection() {
  const theme = useTheme();

  const [checked, setChecked] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const currentRef = ref.current; // Capture the current value at the start of the effect

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setChecked(true);
          observer.unobserve(currentRef); // Use captured ref value
        }
      },
      {
        threshold: 0.6,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use captured ref value in cleanup
      }
    };
  }, []); // No dependencies are necessary here as ref and setChecked do not change

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        bgcolor: theme.palette.grey[100],
      }}
    >
      <Slide
        direction="up"
        in={checked}
        mountOnEnter
        unmountOnExit
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Typography
          variant="h2"
          align="center"
          sx={{
            pt: 4,
            mt: 4,
          }}
          gutterBottom
        >
          Meet our Team
        </Typography>
      </Slide>
      <Slide
        direction="up"
        in={checked}
        mountOnEnter
        unmountOnExit
        {...(checked ? { timeout: 1100 } : {})}
      >
        <Typography
          variant="subtitle1"
          align="center"
          gutterBottom
          sx={{
            color: theme.palette.grey.dark,
            pb: 2,
            mb: 2,
          }}
        >
          Your search Sherpas, guiding you through the recruitment process
        </Typography>
      </Slide>
      {teamMembersData.map((member, index) => (
        <React.Fragment key={member.email}>
          <TeamMemberCard {...member} reverseLayout={index % 2 !== 0} />
          {index !== teamMembersData.length - 1 &&
            teamMembersData.length > 1 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default MeetOurTeamSection;
