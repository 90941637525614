// BaseButton.js
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const BaseButton = styled(Button)(
  ({ theme, textColor, size, ...otherProps }) => {
    const sizeStyles = {
      small: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.5,
        padding: theme.spacing(1, 3),
      },
      medium: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.35,
        padding: theme.spacing(2, 4),
      },
      large: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.2,
        padding: theme.spacing(2, 6),
      },
    };

    return {
      ...(textColor && { color: textColor }),
      ...sizeStyles[size || "medium"],
      ...otherProps.sx,
    };
  }
);

BaseButton.propTypes = {
  textColor: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  sx: PropTypes.object,
};

export default BaseButton;
