//CandidatesCard.js
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  Typography,
  useTheme,
  List,
  ListItem,
  styled,
  useMediaQuery,
} from "@mui/material";

import ContainedButton from "../Buttons/ContainedButton";

const StyledTextCard = styled(Card)(({ theme, bgColor }) => ({
  overflow: "hidden",
  backgroundColor: bgColor,
  boxShadow: "none",
  padding: theme.spacing(6),
  margin: theme.spacing(2, 0),
}));

const CandidatesCard = ({
  title,
  description,
  bulletPoints,
  image,
  bgColor,
  buttonText,
  buttonLink,
  buttonColor,
  buttonTextColor,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledTextCard bgColor={bgColor}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: theme.spacing(2),
          }}
        >
          <CardMedia
            component="img"
            image={image}
            title={title}
            sx={{
              width: "100%",
              height: "auto",
              maxWidth: { xs: 360, sm: 360, md: 360 },
              maxHeight: { xs: 240, sm: 240, md: 240 },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={9}
          sx={{
            paddingLeft: isMobile ? 0 : theme.spacing(2),
            paddingRight: isMobile ? 0 : theme.spacing(2),
          }}
        >
          <CardContent
            sx={{
              margin: theme.spacing(1),
            }}
          >
            <Typography gutterBottom variant="h4">
              {title}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: theme.palette.grey[800] }}
            >
              {description}
            </Typography>
            {bulletPoints && (
              <List
                sx={{
                  listStyleType: "disc",
                  paddingTop: theme.spacing(1),
                  paddingBottom: 0,
                  marginLeft: theme.spacing(3),
                }}
              >
                {bulletPoints.map((point, index) => (
                  <ListItem key={index} sx={{ display: "list-item" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: theme.palette.grey[800] }}
                    >
                      {point}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}

            <ContainedButton
              text={buttonText}
              link={buttonLink}
              textColor={buttonTextColor}
              bgColor={buttonColor}
              sx={{
                mt: theme.spacing(4),
                alignSelf: { xs: "center", md: "flex-start" },
                padding: {
                  xs: theme.spacing(1.5, 4),
                  sm: theme.spacing(2, 5),
                  md: theme.spacing(2, 6),
                },
              }}
            />
          </CardContent>
        </Grid>
      </Grid>
    </StyledTextCard>
  );
};

export default CandidatesCard;
