import { Tooltip, IconButton, styled, tooltipClasses } from "@mui/material";
import PropTypes from "prop-types";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey.dark,
    color: theme.palette.common.white,
    fontSize: "0.80rem",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey.dark,
  },
}));

const IconWithTooltip = ({
  icon: Icon,
  color,
  tooltipText,
  onClick,
  sx,
  size,
}) => {
  const fontSize = size === "large" ? "2.5rem" : "1.5rem";
  const fontWeight = size === "large" ? 500 : 400;
  return (
    <StyledTooltip title={tooltipText} arrow>
      <IconButton
        disableRipple
        onClick={onClick}
        color={color}
        placement="bottom"
        sx={{
          verticalAlign: "middle",
          ...sx,
        }}
      >
        <Icon sx={{ fontSize, fontWeight }} />
      </IconButton>
    </StyledTooltip>
  );
};

IconWithTooltip.propTypes = {
  icon: PropTypes.elementType.isRequired,
  size: PropTypes.oneOf(["small", "large"]),
  color: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

IconWithTooltip.defaultProps = {
  color: "inherit",
  onClick: null,
  sx: {},
  size: "small",
};

export default IconWithTooltip;
