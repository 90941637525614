//EmployersOutro.js

//Component Imports
import EmployersList from "../../components/EmployersPage/EmployersList";
import EmployersOutroList from "../../components/EmployersPage/EmployersOutroList";

const EmployersOutro = () => {
  return (
    <>
      <EmployersList
        title="We are Human Resources & Counseling Experts"
        description="We can help take your business to the next level with solid support and advice on any HR issue within your organization including:"
        listItems={[
          "Performance management",
          "Employee Engagement Programs",
          "Pay for Performance Programs",
          "Proper dismissal practices",
          "Career transition",
          "Workforce planning",
          "Learning and Development ",
          "& Much More! ",
        ]}
      />
      <EmployersOutroList />
    </>
  );
};

export default EmployersOutro;
