//BlogPostDetail.js
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Typography } from "@mui/material";

//Section Imports
import Navbar from "../sections/NavBar/Navbar";
import Footer from "../sections/Footer/Footer";
import TestimonialsSection from "../sections/Testimonials/TestimonialsSection";
import FeaturedPostsSection from "../sections/Blog/FeaturedPostsSection.js";

//Component Imports
import HeroBanner from "../sections/HeroBanner/HeroBanner";

//asset imports
import homeBannerImage from "../assets/AboutHeroBanner.jpg";

function BlogPostDetail() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    fetch(`http://localhost:8000/api/posts/${postId}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPost(data);
      });
  }, [postId]);

  useEffect(() => {
    fetch("http://localhost:8000/api/posts/")
      .then((response) => response.json())
      .then((data) => {
        setRelatedPosts(data.filter((p) => p.id.toString() !== postId));
      });
  }, [postId]);

  const formatContent = (textContent) => {
    return textContent
      .split("\n")
      .map((paragraph, index) => paragraph && <p key={index}>{paragraph}</p>);
  };

  if (!post) return <div>Loading...</div>;

  return (
    <div>
      <Navbar />
      <HeroBanner
        page={post.title}
        backgroundImage={homeBannerImage}
        heading={post.title}
        headingStyle={{ color: "black" }}
        buttonLink="/contact"
      />
      <Container maxWidth="md" sx={{ my: 4 }}>
        {formatContent(post.content)}
      </Container>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Related Posts
        </Typography>
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Link to="/blog" style={{ textDecoration: "none" }}>
            View All
          </Link>
        </div>
        <FeaturedPostsSection posts={relatedPosts} />
      </Container>
      <TestimonialsSection />
      <Footer />
    </div>
  );
}

export default BlogPostDetail;
