//HomeIntro.js
import { useRef } from "react";
import { Box, Grid, Typography, useTheme, Paper } from "@mui/material";

//Component Imports
import HeroBanner from "../HeroBanner/HeroBanner";
import TextButton from "../../components/Buttons/TextButton";

//Asset Imports
import homeBannerImage from "../../assets/HeroBanner.jpg";

const HomeIntro = () => {
  const theme = useTheme();
  const nextSectionRef = useRef(null);

  return (
    <>
      <HeroBanner
        nextSectionRef={nextSectionRef}
        page={"Home"}
        backgroundImage={homeBannerImage}
        heading="Hard to find talent, doesn’t have to be hard to find!"
        headingStyle={{}}
        buttonText="Get Started"
        buttonLink="/contact"
      />
      <Paper
        ref={nextSectionRef}
        sx={{
          my: { xs: theme.spacing(2), md: theme.spacing(4) },
          padding: { xs: theme.spacing(2), md: theme.spacing(4) },
        }}
        elevation={0}
      >
        <Box px={theme.spacing(3)} py={theme.spacing(5)} sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                align="left"
                sx={{ paddingBottom: theme.spacing(3) }}
              >
                GB Talent Solutions makes your greatest assets stronger
              </Typography>
              <Typography variant="subtitle1" paragraph align="left">
                The best candidates in the market are often not actively
                looking. GB Talent Solutions connects employers with the best
                talent in the market, whether they are looking or not. If you
                are hiring a key impact role, the difference between your
                average applicant, and an expert that is brought in to hit the
                ground running with their network, knowledge, experience can
                transform your business far past your current targets. GB Talent
                Solutions has a background of consistently bringing in talent
                that will go far past your targets. The best part? It is risk
                free!
              </Typography>
              <TextButton
                text="Learn More"
                link="/about"
                size="large"
                textColor={theme.palette.primary.dark}
                sx={{
                  ml: theme.spacing(0),
                  padding: theme.spacing(0),
                }}
                textSize={"1.5rem"}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default HomeIntro;
