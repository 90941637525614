//FooterBridge.js
import {
  Paper,
  useTheme,
  Grid,
  useMediaQuery,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import { motion } from "framer-motion";

//Section Imports
import Footer from "../Footer/Footer";

//Component Imports
import ContactOverlay from "../../components/ContactOverlay/ContactOverlay";
import TextButton from "../../components/Buttons/TextButton";

const FooterBridge = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const titleVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const descriptionVariants = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <>
      <Paper elevation={0} sx={{ position: "relative", marginTop: 5 }}>
        <Grid container spacing={4} sx={{ marginBottom: 20 }}>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{ textAlign: isMobile ? "center" : "right" }}
          >
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleVariants}
              transition={{ duration: 1 }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  marginTop: theme.spacing(5),
                }}
              >
                Our Technique
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={9}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={descriptionVariants}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <List
                sx={{
                  listStyleType: "disc",
                  margin: theme.spacing(3),
                  fontSize: "1.5rem",
                  fontWeight: 550,
                  color: theme.palette.grey[800],
                }}
              >
                <ListItem
                  sx={{
                    display: "list-item",
                  }}
                >
                  We'll connect you to the best of the best.
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                  }}
                >
                  Our network consists of both passive and active candidates
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                  }}
                >
                  We employ active headhunting calls
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                  }}
                >
                  We check references and then we check again
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                  }}
                >
                  We get great referrals
                </ListItem>
                <TextButton
                  text="Learn more about us!"
                  link="/employers"
                  size="large"
                  textColor={theme.palette.primary.dark}
                  sx={{
                    ml: theme.spacing(1),
                    padding: theme.spacing(1),
                  }}
                  textSize={"1.5rem"}
                />
              </List>
            </motion.div>
          </Grid>
        </Grid>
        <ContactOverlay />
        <Footer />
      </Paper>
    </>
  );
};

export default FooterBridge;
