//EmployersIntro.js
import { Paper, Typography, useTheme, Box } from "@mui/material";

//Component Imports
import EmployersTextCard from "../../components/EmployersPage/EmployersTextCard";
import EmployersList from "../../components/EmployersPage/EmployersList";

function EmployersIntro() {
  const theme = useTheme();

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          mx: "auto",
          my: { xs: 2, md: 4 },
          padding: { xs: 2, md: 4 },
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: { xs: "center", md: "space-between" },
            pr: { xs: 0, md: 4 },
            maxWidth: { xs: "calc(100% - 2rem)", md: "60rem" },
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              color: theme.palette.grey.dark,
            }}
          >
            People are your biggest investment and greatest risk—a great hire is
            the goal! Top Tier will help you find, (and once hired), sustain and
            grow your valuable talent.
          </Typography>
        </Box>
      </Paper>
      <Box sx={{ width: "100%" }}>
        <EmployersTextCard
          quote="Full-cycle support from sourcing, screening and presentation to negotiation"
          description="Every profile that is sent to our clients includes a full candidate profile explaining why this candidate will add significant value to your organization. This detailed introduction includes compensation expectations to ensure that there are no surprises at end stages. We will not send you candidates who have already applied, if great people are walking in your door that is amazing, our goal is to find people that are better than the inflow.  We conduct thorough pre-screening interviews with our candidates to ensure that we are presenting them with the right opportunity and we have amazing results in getting offers signed."
          bgColor={theme.palette.primary.light}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <EmployersList
          title="Meeting the Best of the Best"
          listItems={[
            "Outbound targeted headhunting",
            "Bringing in talent that adds value!",
            "Executive and professional search",
            "Global search professionals with a limitless reach",
            "The best technology in the market.",
            "Personal and professional approach that doesn’t overuse automation.",
            "Free workforce planning discussions",
            "Always honest, always transparent",
            "Expertise in a broad range of markets",
          ]}
        />
      </Box>
    </>
  );
}

export default EmployersIntro;
