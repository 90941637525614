//App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Pages Imports
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import EmployersPage from "./pages/EmployersPage";
import CandidatesPage from "./pages/CandidatesPage";
import BlogPage from "./pages/BlogPage";
import ContactPage from "./pages/ContactPage";
import BlogPostDetail from "./pages/BlogPostDetail";
import Resumes from "./pages/Resumes";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/employers" element={<EmployersPage />} />
        <Route path="/candidates" element={<CandidatesPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:postId" element={<BlogPostDetail />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/candidates/resumes" element={<Resumes />} />
      </Routes>
    </Router>
  );
};

export default App;
