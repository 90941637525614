//AboutSection.js
import { Box, Typography, Paper, useTheme } from "@mui/material";
import ToggleButton from "../../components/Buttons/ToggleButton";

//Asset Imports
import handshakeImage from "../../assets/Shaking hands_0.jpg";

function AboutSection() {
  const theme = useTheme();
  const commonTypographyStyles = {
    paragraph: true,
    variant: "subtitle2",
    sx: {
      color: theme.palette.grey.dark,
      m: 4,
    },
  };
  return (
    <Paper
      sx={{
        my: { xs: theme.spacing(2), md: theme.spacing(4) },
        padding: { xs: theme.spacing(2), md: theme.spacing(4) },
      }}
      elevation={0}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: theme.spacing(4),
          pb: theme.spacing(4),
          color: theme.palette.grey.dark,
        }}
      >
        Connections are Critical, collaboration is key.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
        }}
      >
        <Box
          component="img"
          src={handshakeImage}
          alt="Team Handshake"
          sx={{
            width: "100%",
            height: "auto",
            maxWidth: { xs: "100%", md: "34.25rem" },
            m: theme.spacing(2),
          }}
        />
        <Box
          sx={{
            pr: { xs: theme.spacing(0), md: theme.spacing(4) },
            flex: "1",
          }}
        >
          <Typography {...commonTypographyStyles}>
            GB Talent Solutions is focused on providing all outsourced Human
            Resource services for its’ clients. Our founder Graeme Burke has
            been helping companies to grow for the entirety of his career and
            has worked for some of Canada’s fastest growing organizations.
          </Typography>
          <ToggleButton sx={{ paddingBottom: 0, paddingTop: 0 }}>
            <Typography {...commonTypographyStyles}>
              Whether it is helping find the best of the best talent or helping
              companies to execute on all functions of a human resources
              department, we are here to make our clients lives easier, and help
              them to grow faster than they had every thought possible.
            </Typography>
            <Typography {...commonTypographyStyles}>
              GB Talent Solutions is also here to help talented people showcase
              themselves strategically by bringing resumes to the attention of
              the best employers, providing career and interview coaching, and
              helping candidates to have the confidence they need to succeed in
              their job searches.
            </Typography>
            <Typography {...commonTypographyStyles}>
              Our philosophy is to find the best people for the job that will be
              an excellent fit for the organizations culture and goals. We
              firmly believe that one great hire can change the trajectory of a
              growing business, and we aim to help our clients reach new
              heights.
            </Typography>
          </ToggleButton>
        </Box>
      </Box>
    </Paper>
  );
}

export default AboutSection;
