//ContactForm.js
import { useRef, useState } from "react";
import { TextField, Box, Button, useTheme } from "@mui/material";
import emailjs from "@emailjs/browser";

//Component Imports
import UploadResumeButton from "./UploadResumeButton";

const ContactForm = ({ onSendEmail }) => {
  const theme = useTheme();
  const formRef = useRef();

  //EmailJs Credentials
  const SERVICE_ID = "service_pum149f";
  const TEMPLATE_ID = "template_6phooql";
  const PUBLIC_KEY = "c3lvgJHcSoAEF_j9f";

  //States
  const [form, setForm] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    message: "",
    user_resume: null,
  });
  const [uploadedFile, setUploadedFile] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    // Create a form data object to send to EmailJS
    const formData = new FormData();
    formData.append("user_name", form.user_name);
    formData.append("user_email", form.user_email);
    formData.append("user_phone", form.user_phone);
    formData.append("message", form.message);
    if (form.user_resume) {
      formData.append("user_resume", form.user_resume);
    }

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, {
        publicKey: PUBLIC_KEY,
      })
      .then(
        (result) => {
          onSendEmail("success", "Message sent successfully!");
          resetForm();
        },
        (error) => {
          onSendEmail("error", "Failed to send message. Please try again.");
        }
      );
  };

  const resetForm = () => {
    setForm({
      user_name: "",
      user_email: "",
      user_phone: "",
      message: "",
      user_resume: null,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  // Handle file selection or removal
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const allowedTypes = [
      ".txt",
      ".rtf",
      ".pdf",
      ".doc",
      ".docx",
      ".odt",
      ".ppt",
      ".pptx",
      ".odp",
      ".xls",
      ".xlsx",
      ".ods",
    ];
    if (file) {
      // Validate file type
      if (
        allowedTypes.includes(
          file.name.toLowerCase().substring(file.name.lastIndexOf("."))
        )
      ) {
        // File has allowed file type; Update the form state
        setForm({ ...form, user_resume: file });
        setUploadedFile(file.name);
        setIsFileUploaded(true);
      } else {
        //Invalid file type, rest state and show error
        e.target.value = "";
        alert("Invalid file type. Please select an allowed file.");
      }
    } else {
      if (isFileUploaded) {
        setForm({ ...form, user_resume: null });
        setUploadedFile("");
        setIsFileUploaded(false);
      }
    }
  };

  const gradientColor = `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.blue.light} 90%)`;
  const darkenGradient = (gradient) => {
    return `${gradient}, rgba(0, 0, 0, 0.2)`;
  };

  return (
    <>
      <Box
        component="form"
        ref={formRef}
        noValidate
        onSubmit={sendEmail}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: theme.spacing(1.25),
          padding: theme.spacing(1.25),
          "& .MuiTextField-root": { m: 1, width: "100%" },
          "& .MuiButton-root": { m: 1, width: "auto" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <TextField
            label="What's your Name?"
            name="user_name"
            variant="outlined"
            required
            onChange={handleInputChange}
            sx={{ width: { xs: "100%", md: "48%" } }}
          />
          <TextField
            label="What's your email?"
            name="user_email"
            type="email"
            variant="outlined"
            required
            onChange={handleInputChange}
            sx={{ width: { xs: "100%", md: "48%" } }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <TextField
            label="What's your contact number?"
            name="user_phone"
            variant="outlined"
            onChange={handleInputChange}
            sx={{ width: { xs: "100%", md: "48%" } }}
          />
          <UploadResumeButton
            onFileSelect={handleFileChange}
            uploadedFile={uploadedFile}
            isFileUploaded={isFileUploaded}
            setUploadedFile={setUploadedFile}
            setIsFileUploaded={setIsFileUploaded}
            setForm={setForm}
          />
        </Box>
        <TextField
          label="What do you want to say?"
          name="message"
          variant="outlined"
          multiline
          minRows={4}
          required
          fullWidth
          onChange={handleInputChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            fontSize: theme.typography.pxToRem(22),
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: 1.2,
            padding: theme.spacing(2, 8),
            width: 250,
            boxShadow: theme.shadows[0.65],
            backgroundColor: "transparent",
            backgroundImage: gradientColor,
            borderRadius: "10px",

            "&:hover": {
              color: "white",
              boxShadow: "inset 0 0 100px rgba(0, 0, 0, 0.15)",
              backgroundImage: darkenGradient(gradientColor),
              backgroundColor: "transparent",
              transition: "background-color 0.9s ease, color 0.7s ease",
            },
          }}
        >
          Send
        </Button>
      </Box>
    </>
  );
};

export default ContactForm;
