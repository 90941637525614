//UploadResumeButton.js
import { Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

//Component imports
import BaseButton from "../Buttons/BaseButton";
import IconWithTooltip from "../IconWithToolTip/IconWithToolTip";

//Icons Import
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

const UploadResumeButton = ({
  onFileSelect,
  uploadedFile,
  isFileUploaded,
  setUploadedFile,
  setIsFileUploaded,
  setForm,
}) => {
  const theme = useTheme();

  const gradientColor = `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.blue.light} 90%)`;
  const toolTipContent =
    "One file only. 64 MB limit. Allowed types: txt, rtf, pdf, doc, docx, odt, ppt, pptx, odp, xls, xlsx, ods.";

  const buttonIcon = isFileUploaded ? <CloseIcon /> : <FileUploadIcon />;

  const darkenGradient = (gradient) => {
    return `${gradient}, rgba(0, 0, 0, 0.2)`;
  };

  const handleButtonClick = (event) => {
    const fileInput = document.getElementById("resume-upload");

    if (isFileUploaded) {
      event.preventDefault();
      event.stopPropagation();

      setUploadedFile("");
      setIsFileUploaded(false);
      setForm((prevForm) => ({ ...prevForm, user_resume: null }));

      if (fileInput) {
        fileInput.value = "";
      }
    } else {
      fileInput.click();
    }
  };

  return (
    <>
      <input
        accept=".txt, .rtf, .pdf, .doc, .docx, .odt, .ppt, .pptx, .odp, .xls, .xlsx, .ods"
        id="resume-upload"
        type="file"
        hidden
        onChange={onFileSelect}
        aria-label="Upload resume"
      />
      <div
        style={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <BaseButton
          onClick={handleButtonClick}
          component="span"
          textColor={theme.palette.primary.contrastText}
          size="large"
          endIcon={buttonIcon}
          sx={{
            width: 250,
            boxShadow: theme.shadows[0.65],
            backgroundColor: "transparent",
            backgroundImage: gradientColor,
            borderRadius: "10px",

            "&:hover": {
              color: "white",
              boxShadow: "inset 0 0 100px rgba(0, 0, 0, 0.15)",
              backgroundImage: darkenGradient(gradientColor),
              backgroundColor: "transparent",
              transition: "background-color 0.9s ease, color 0.7s ease",
            },
          }}
        >
          <Typography
            variant="body1"
            noWrap
            sx={{
              textAlign: "center",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "calc(90%)",
            }}
          >
            {uploadedFile || "Upload resume"}
          </Typography>
        </BaseButton>
        <IconWithTooltip icon={InfoOutlinedIcon} tooltipText={toolTipContent} />
      </div>
    </>
  );
};

UploadResumeButton.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
};

export default UploadResumeButton;
