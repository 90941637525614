//EmployersOutroList.js
import {
  useTheme,
  List,
  ListItem,
  Grid,
  Box,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";

//Component Imports
import ToggleButton from "../Buttons/ToggleButton";

const titleVariants = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const descriptionVariants = {
  hidden: { x: 100, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const EmployersOutroList = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      px={theme.spacing(3)}
      py={theme.spacing(5)}
      sx={{ flexGrow: 1, bgcolor: theme.palette.primary.light }}
    >
      <Grid
        container
        spacing={4}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid
          item
          xs={12}
          sm={3}
          sx={{ textAlign: isMobileOrTablet ? "center" : "right" }}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={titleVariants}
            transition={{ duration: 1 }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: 700,
                padding: theme.spacing(2),
              }}
            >
              Our offerings to Employers
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12} sm={9}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={descriptionVariants}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <List
              sx={{
                paddingLeft: isMobileOrTablet ? theme.spacing(1) : 0,
                listStyleType: "disc",
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
              component="nav"
              aria-labelledby="our-offerings-to-employers"
            >
              <ListItem
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  lineHeight: 1.75,
                }}
              >
                GB Talent Solutions Provides Flexible Options for Employers
                <List component="div" disablePadding sx={{ pl: 4 }}>
                  <ListItem
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      ml: theme.spacing(4),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.25rem",
                        fontWeight: 600,
                        lineHeight: 1.75,
                      }}
                    >
                      Executive Search Full-Service Option –{" "}
                      <span
                        style={{
                          fontSize: "1.125rem",
                          fontWeight: "normal",
                          lineHeight: 1.75,
                        }}
                      >
                        {" "}
                        GB Talent Solutions handles everything!
                      </span>
                    </Typography>
                    <ToggleButton
                      textColor={theme.palette.common.black}
                      hoverColor={theme.palette.common.white}
                      sx={{
                        ml: theme.spacing(0),
                        padding: theme.spacing(1, 0),
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          lineHeight: 1.75,
                          padding: theme.spacing(2, 0),
                        }}
                      >
                        We will manage advertising campaigns at our cost and
                        handle all inbound applications from any channel. In
                        addition to this, we conduct industry and labour market
                        research to ensure that our outbound headhunting
                        offering allows you to meet with the best of the best in
                        the talent pool, even if they are not actively looking.
                        We interview, shortlist, schedule, and handle everything
                        so that all you need to do is meet with the best of the
                        best and decide who you would like to bring on and add
                        value to the group!
                      </Typography>
                    </ToggleButton>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      ml: theme.spacing(4),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.25rem",
                        fontWeight: 600,
                        lineHeight: 1.75,
                      }}
                    >
                      Contingent Headhunting Option –{" "}
                      <span
                        style={{
                          fontSize: "1.125rem",
                          fontWeight: "normal",
                          lineHeight: 1.75,
                        }}
                      >
                        {" "}
                        Working alongside your team to get the best people to
                        your door at no cost to engage!
                      </span>
                    </Typography>
                    <ToggleButton
                      textColor={theme.palette.common.black}
                      hoverColor={theme.palette.common.white}
                      sx={{
                        ml: theme.spacing(0),
                        padding: theme.spacing(1, 0),
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          lineHeight: 1.75,
                          padding: theme.spacing(2, 0),
                          color: theme.palette.grey[800],
                        }}
                      >
                        Many contingent headhunters will compete with internal
                        processes by putting up advertising and getting to
                        active applicants first. We do not believe in this
                        approach for contingent headhunting as if someone is
                        walking in the door, they should not come with a fee.
                        With this approach, our team conducts detailed research
                        and utilizes industry-leading technology to ensure we
                        are reaching out to people who are not actively looking
                        at job advertisements. This approach gives our clients
                        peace of mind that when they are making a key hiring
                        decision, they have met with the best talent in the
                        market and they are making the right hiring choice.
                      </Typography>
                    </ToggleButton>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  lineHeight: 1.75,
                }}
              >
                We also offer volume discounts for our regular clients!
              </ListItem>
            </List>
          </motion.div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployersOutroList;
