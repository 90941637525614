//AboutPage.js
//Author: Kunal Rajpal | rajpal.kunal1@gmail.com

//Section Imports
import Navbar from "../sections/NavBar/Navbar.js";
import TestimonialsSection from "../sections/Testimonials/TestimonialsSection.js";
import Footer from "../sections/Footer/Footer.js";
import HeroBanner from "../sections/HeroBanner/HeroBanner.js";
import ResumeTips from "../sections/Candidates/ResumeTips.js";
//Asset Imports
import homeBannerImage from "../assets/AboutHeroBanner.jpg";

function AboutPage() {
  return (
    <>
      <Navbar />
      <HeroBanner
        page={"ResumeTips"}
        backgroundImage={homeBannerImage}
        heading="Connecting Top Talent with Top Employers"
        headingStyle={{
          color: "black",
        }}
        buttonText="Find your Fit now"
        buttonLink="/contact"
      />
      <ResumeTips />
      <TestimonialsSection />
      <Footer />
    </>
  );
}

export default AboutPage;
