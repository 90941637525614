//Navbar.js
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import Logo from "../../assets/Logo.png";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Navbar(props) {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const buttonStyle = isScrolled ? { color: "white" } : { color: "black" };

  return (
    <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        elevation={isScrolled ? 4 : 0}
        style={{
          transition: "0.3s",
          backgroundColor: isScrolled ? "#000" : "transparent",
          boxShadow: isScrolled ? "" : "none",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}
            >
              <img src={Logo} alt="Top Tier Talent Logo" height="50" />
            </Typography>
            <Box sx={{ flexGrow: 1 }} />{" "}
            {/* This box pushes all elements after it to the right */}
            <Box sx={{ flexGrow: 0, display: "flex", alignItems: "centre" }}>
              <Button style={buttonStyle} href="/">
                Home
              </Button>
              <Button style={buttonStyle} href="/about">
                About
              </Button>
              <Button style={buttonStyle} href="/employers">
                Employers
              </Button>
              <Button style={buttonStyle} href="/candidates">
                Candidates
              </Button>
              {/* <Button style={buttonStyle} href="/blog">
                Blog
              </Button> */}
              <Button style={buttonStyle} href="/contact">
                Contact
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}

export default Navbar;
