// ContainedButton.js
import BaseButton from "./BaseButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

const ContainedButton = ({ text, link, textColor, bgColor, size, sx }) => {
  const theme = useTheme();
  const isGradient = bgColor?.includes("gradient");

  const darkenGradient = (gradient) => {
    return `${gradient}, rgba(0, 0, 0, 0.2)`;
  };

  return (
    <BaseButton
      component="a"
      href={link}
      size={size}
      textColor={textColor}
      sx={{
        backgroundColor: !isGradient
          ? bgColor || theme.palette.secondary.main
          : "transparent",
        ...(isGradient && {
          backgroundImage: bgColor,
        }),
        borderRadius: "50px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",

        "&:hover": {
          color: "white",
          boxShadow: isGradient
            ? "inset 0 0 100px rgba(0, 0, 0, 0.15)"
            : "none",
          backgroundImage: isGradient ? darkenGradient(bgColor) : "none",
          backgroundColor: !isGradient
            ? theme.palette.secondary.dark
            : "transparent",
          transition: "background-color 0.9s ease, color 0.7s ease",
        },
        ...sx,
      }}
    >
      {text}
    </BaseButton>
  );
};

ContainedButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  sx: PropTypes.object,
};

export default ContainedButton;
