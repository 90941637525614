//ContactOverlay.js
//Author: Kunal Rajpal | rajpal.kunal1@gmail.com
import { Box, Typography, useTheme } from "@mui/material";
import ContainedButton from "../Buttons/ContainedButton";

const ContactOverlay = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        width: { xs: "75%", md: "67%" },
        bottom: { xs: "42%", sm: "46%", md: "38%" },
        left: { xs: "13%", sm: "15%", md: "19%" },
        right: 0,
        height: { xs: "17vh", md: "25vh" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage:
          "linear-gradient(135deg, #74B7BB, #6296C6, #5278D1, #6078C7, #6F79BC)",
        zIndex: 10,
        p: { xs: 2, sm: 0, md: 0 },
      }}
    >
      <Typography
        color={theme.palette.common.white}
        gutterBottom
        sx={{
          fontWeight: 600,
          fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" },
          textAlign: "center",
          paddingBottom: 2,
        }}
      >
        Your successful search begins right here. Right now.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <ContainedButton
          text={"Contact"}
          link={"/contact"}
          textColor={theme.palette.common.white}
          bgColor="#5BA9AD"
          sx={{
            fontSize: { xs: "0.75rem", sm: "1.125rem", md: "1.3rem" },
            padding: {
              xs: theme.spacing(1, 3),
              sm: theme.spacing(1.5, 5),
              md: theme.spacing(2, 6.2),
            },
            "&:hover": {
              color: "#5BA9AD",
              backgroundImage: "none",
              backgroundColor: theme.palette.common.white,
              transition: "background-color 0.9s ease, color 0.7s ease",
              boxShadow: "none",
            },
          }}
        />
        <Typography
          color={theme.palette.common.white}
          sx={{
            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          204-960-5403
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactOverlay;
