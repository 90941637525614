//EmployersTextCard.js
import {
  Typography,
  styled,
  Card,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";

const StyledTextCard = styled(Card)(({ theme, bgColor }) => ({
  backgroundColor: bgColor,
  boxShadow: "none",
  padding: theme.spacing(6),
  margin: theme.spacing(2, 0),
}));

const EmployersTextCard = ({ quote, description, bgColor }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledTextCard bgColor={bgColor}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            textAlign: isMobile ? "left" : "right",
            paddingRight: isMobile ? 0 : theme.spacing(2),
          }}
        >
          <Typography variant="h5" gutterBottom>
            {quote}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          sx={{
            paddingLeft: isMobile ? 0 : theme.spacing(2),
            paddingRight: isMobile ? 0 : theme.spacing(2),
          }}
        >
          <Typography
            variant="subtitle2"
            align="left"
            sx={{ color: theme.palette.grey[800] }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </StyledTextCard>
  );
};

EmployersTextCard.propTypes = {
  quote: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
};

export default EmployersTextCard;
