//theme.js
import { createTheme } from "@mui/material/styles";
import { grey, blueGrey, blue } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7CE7EB",
      main: "#5CE1E6",
      dark: "#409DA1",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      light: "#7BBEAE",
      main: "#5BAE9A",
      dark: "#3F796B",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    grey: {
      light: grey[200],
      medium: grey[300],
      main: grey[400],
      dark: grey[700],
      extraDark: grey[900],
    },
    blueGrey: {
      light: blueGrey[200],
      main: blueGrey[400],
      dark: blueGrey[700],
    },
    blue: {
      light: blue[200],
      main: blue[400],
      dark: blue[700],
    },
  },
  typography: {
    fontFamily: ["Work Sans", "sans-serif"].join(","),
    h1: {
      fontSize: "3.5rem",
      fontWeight: 600,
      lineHeight: 1.167,
      textTransform: "uppercase",
    },
    h2: {
      fontSize: "2.75rem",
      fontWeight: 600,
      lineHeight: 1.2,
      textTransform: "capitalize",
    },
    h3: {
      fontSize: "2.25rem",
      fontWeight: 600,
      lineHeight: 1.167,
      textTransform: "capitalize",
    },
    h4: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1.235,
      textTransform: "capitalize",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.334,
      textTransform: "capitalize",
    },
    h6: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.6,
      textTransform: "capitalize",
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "1.15rem",
      fontWeight: 400,
      lineHeight: 1.57,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.43,
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.75,
      textTransform: "none",
    },
    caption: {
      fontSize: "0.65rem",
      fontWeight: 400,
      lineHeight: 1,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 2.66,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      html {
        scroll-behavior: smooth;
      }
      `,
    },
  },
});

export default theme;
