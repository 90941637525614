// ContactPage.js
import { useState, useCallback } from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
//Component Imports
import Navbar from "../sections/NavBar/Navbar";
import TestimonialsSection from "../sections/Testimonials/TestimonialsSection";
import Footer from "../sections/Footer/Footer";
import HeroBanner from "../sections/HeroBanner/HeroBanner";

//Contact Section Imports
import ContactFormSection from "../sections/Contact/ContactFormSection";

// Asset Imports
import contactBannerImage from "../assets/ContactHeroBanner.jpg";

const ContactPage = () => {
  //States
  const [alert, setAlert] = useState({ open: false, type: "", message: "" });

  const handleAlert = useCallback((type, message) => {
    setAlert({ open: true, type, message });
  }, []);

  const closeAlert = useCallback(() => {
    setAlert({ ...alert, open: false });
  }, [alert]);

  return (
    <>
      <Navbar />
      <HeroBanner
        page={"Contact"}
        backgroundImage={contactBannerImage}
        heading="Contact me"
        headingStyle={{
          color: "white",
        }}
      />
      <ContactFormSection onSendEmail={handleAlert} />
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert
          variant="filled"
          severity={alert.type}
          onClose={closeAlert}
          sx={{
            width: "100%",
            boxShadow: 3,
          }}
        >
          <AlertTitle>
            {alert.type === "success" ? "Submitted" : "Submission Error"}
          </AlertTitle>
          {alert.message}
        </Alert>
      </Snackbar>
      <TestimonialsSection />
      <Footer />
    </>
  );
};

export default ContactPage;
