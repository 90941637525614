//AboutPage.js

//Section Imports
import Navbar from "../sections/NavBar/Navbar.js";
import TestimonialsSection from "../sections/Testimonials/TestimonialsSection.js";
import Footer from "../sections/Footer/Footer.js";
import HeroBanner from "../sections/HeroBanner/HeroBanner.js";
import AboutSection from "../sections/About/AboutSection.js";
import MeetOurTeamSection from "../sections/About/MeetOurTeamSection.js";

//Asset Imports
import homeBannerImage from "../assets/AboutHeroBanner.jpg";

import { useTheme } from "@mui/material";

function AboutPage() {
  const theme = useTheme();
  return (
    <>
      <Navbar />
      <HeroBanner
        page={"About"}
        backgroundImage={homeBannerImage}
        heading="Connecting Talent with Employers"
        headingStyle={{
          color: theme.palette.grey.extraDark,
        }}
        buttonText="Find your Fit now"
        buttonLink="/contact"
      />
      <AboutSection />
      <MeetOurTeamSection />
      <TestimonialsSection />
      <Footer />
    </>
  );
}

export default AboutPage;
