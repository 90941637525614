// HomePage.js

//Section imports
import TestimonialsSection from "../sections/Testimonials/TestimonialsSection";
import Navbar from "../sections/NavBar/Navbar";
import HomeIntro from "../sections/Home/HomeIntro";
import FooterBridge from "../sections/Home/FooterBridge";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <HomeIntro />
      <TestimonialsSection />
      <FooterBridge />
    </>
  );
};

export default HomePage;
