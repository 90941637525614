// CustomButton.js
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const CustomButton = styled(Button)(
  ({ theme, size, bgColor, textColor, variant, rounded, ...otherProps }) => {
    const isGradient = bgColor?.includes("gradient");

    const darkenGradient = (gradient) => {
      return `${gradient}, rgba(0, 0, 0, 0.2)`; // Darken by overlaying a semi-transparent black
    };

    //Styling based on size
    const sizeStyles = {
      small: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(1, 3),
        lineHeight: 1.5,
        textTransform: "uppercase",
      },
      medium: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(2, 4),
        lineHeight: 1.35,
        textTransform: "uppercase",
      },
      large: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(2, 6),
        lineHeight: 1.2,
        textTransform: "uppercase",
      },
      extraLarge: {
        fontSize: theme.typography.pxToRem(28),
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(4, 22),
        lineHeight: 1,
        textTransform: "uppercase",
      },
    };

    const iconSize = sizeStyles[size || "medium"].fontSize;

    return {
      margin: theme.spacing(1),
      color: textColor || theme.palette.primary.contrastText,
      backgroundColor:
        variant === "contained" && !isGradient
          ? bgColor || theme.palette.primary.main
          : "transparent",
      ...(isGradient &&
        variant === "contained" && {
          backgroundImage: bgColor,
        }),
      ...sizeStyles[size || "medium"],
      borderRadius: rounded ? "50px" : theme.shape.borderRadius,
      boxShadow:
        variant === "contained" ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
      "& .MuiButton-startIcon": {
        fontSize: iconSize,
      },
      "&:hover": {
        ...(variant === "contained" && {
          color: "white",
          boxShadow: isGradient
            ? "inset 0 0 100px rgba(0, 0, 0, 0.15)"
            : "none",
          backgroundImage: isGradient ? darkenGradient(bgColor) : "none",
          backgroundColor: !isGradient
            ? theme.palette.primary.dark
            : "transparent",
          transition: "background-color 0.9s ease, color 0.s ease",
        }),
        ...(variant === "text" && {
          color: theme.palette.primary.dark,
          transition: "color 0.9s ease",
          backgroundColor: "transparent",
          backgroundImage: "none",
        }),
      },
      ...otherProps.sx,
    };
  }
);

CustomButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large", "extraLarge"]),
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  rounded: PropTypes.bool,
  sx: PropTypes.object,
};

export default CustomButton;
