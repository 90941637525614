//TestimonialsSection.js
import { useState, useEffect } from "react";
import { Box, Typography, MobileStepper, useTheme, Slide } from "@mui/material";
import { useNavigate } from "react-router-dom";

//Asset imports
import testimonialsSectionImage from "../../assets/Testimonials.jpg";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LeakAddOutlinedIcon from "@mui/icons-material/LeakAddOutlined";

//component Imports
import CustomButton from "../../components/Buttons/CustomButton";

// Testimonial data
const testimonials = [
  {
    quote: `I have worked with Graeme a few times and highly recommend him to anyone 
    who is thinking about working with a recruiter for their hiring needs. 
    Graeme took the time to learn about our company and team and understand our needs. 
    Each time I have worked with him, he has provided quality candidates. With Graeme's help, 
    we have been successfully growing our team. I look forward to working with him again in 
    the future.`,
    author: "Tina Wood, Director of Marketing and Communications",
  },
  {
    quote: `I have had the opportunity to work with Graeme on many occasions and time after time
    he produces results. Communication is always timely and professional, and I know that
    when I require his help, he is giving me 110% of his effort. Graeme takes each search
    very seriously and truly wants the best for his clients.`,
    author: "Amy Hildebrandt, Human Resource Generalist",
  },
  {
    quote: `I worked hand in hand with Graeme on multiple positions at Neo. 
    He is a great asset and always went above and beyond!`,
    author: "Paul Card, Managing Director, Neo Financial",
  },
];

const TestimonialsSection = () => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = testimonials.length;
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [maxSteps]);

  const handleGetStartedClick = () => navigate("/contact");
  const handleFindYourFitClick = () => navigate("/employers");

  const sectionStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main, // This sets the base color
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${testimonialsSectionImage})`, // Added more opacity for text readability
    backgroundSize: "cover",
    backgroundPosition: "center",
    textAlign: "center",
    height: "50vh", // This sets a fixed height based on the viewport height
    "@media (max-width:600px)": {
      height: "60vh", // Adjusts the height for screens smaller than 600px
    },
    width: "100%", // This ensures the section spans the full width of its container
    overflow: "hidden", // This will prevent content from spilling outside the section
  };

  return (
    <Box sx={sectionStyle}>
      {/* Heading */}
      <Typography
        variant="h3"
        component="h2"
        sx={{ fontWeight: "bold", mt: "5vh", mb: "7vh" }}
      >
        Our Track Record
      </Typography>

      {/* Quote */}
      <Box
        sx={{
          mx: "auto",
          px: "5vw",
          minHeight: "10vh",
          maxHeight: "20vh",
          width: "70vh",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontStyle: "italic", textAlign: "center" }}
        >
          {testimonials[activeStep].quote}
        </Typography>
      </Box>

      {/* Author */}
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: "2vh" }}>
        - {testimonials[activeStep].author}
      </Typography>

      {/* Vertical button dots */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "1.25rem",
          transform: "translateY(-50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={null}
          backButton={null}
          sx={{
            backgroundColor: "transparent",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            ".MuiMobileStepper-dot": {
              margin: "10px 0",
              width: "0.875em",
              height: "0.875em",
              borderRadius: "50%",
              transition: "transform 0.3s ease-in-out", // Smooth transition for active/inactive state
              border: "1px solid white",
              backgroundColor: theme.palette.primary.light,
            },
            ".MuiMobileStepper-dotActive": {
              transform: "scale(1.25)", // Slightly scale up the active dot
              backgroundColor: "#FFF", // Active dot color
              boxShadow: `0 0 0 2px ${theme.palette.background.paper}`, // Loose outline effect
            },
            ".MuiMobileStepper-dots": {
              flexDirection: "column",
              cursor: "pointer",
            },
          }}
        />
      </Box>
      {/* Action Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mt: 4,
        }}
      >
        <Slide
          direction="right"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <CustomButton
            variant="contained"
            size="extraLarge"
            startIcon={<LeakAddOutlinedIcon />}
            onClick={handleGetStartedClick}
            sx={{
              //...ButtonStyles,
              color: "white",
              borderColor: "black",
              bgcolor: "black",
              "&:hover": {
                bgcolor: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
              },
            }}
          >
            Get Started
          </CustomButton>
        </Slide>
        <Slide
          direction="left"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <CustomButton
            variant="contained"
            size="extraLarge"
            startIcon={<ArticleOutlinedIcon />}
            onClick={handleFindYourFitClick}
            sx={{
              //...ButtonStyles,
              color: "white",
              bgcolor: theme.palette.grey.dark,
              borderColor: theme.palette.grey.dark,
              "&:hover": {
                bgcolor: theme.palette.secondary.dark,
                borderColor: theme.palette.secondary.dark,
              },
            }}
          >
            Find Your Fit
          </CustomButton>
        </Slide>
      </Box>
    </Box>
  );
};

export default TestimonialsSection;
