//CandidatesSection.js
import { Box, useTheme } from "@mui/material";

//Component Imports
import CandidatesCard from "../../components/CandidatesPage/CandidatesCard";

//Asset Imports
import ResumeImage from "../../assets/CandidateImg1.jpg";
import CareerImage from "../../assets/CandidateImg2.jpg";
import yourImage from "../../assets/Shaking hands_0.jpg";

const candidatesServices = [
  {
    title: "Build An Effective Resume",
    description:
      "Resume formats have changed entirely in recent years, and that’s why many people searching for new jobs find updating their current resume to be a daunting task. Hiring managers all have different preferences and expectations of a well-crafted resume, so how do you build one that resonates with them, is attractive and effective and won’t get shoved to the bottom of the pile? Don’t know where to get started? We have your back! GB Talent Solutions will meet with you and build you a custom resume that will have you feeling confident in reaching out to prospective employers. Not only that, but we will also show you how to effectively tweak it to stand our to each specific opportunity you are applying to. Our resume service costs $150 CAD, and the best part is this fee is fully refunded if we end up directly connecting you with an employer that you sign on with!",
    buttonText: "Resume Resources",
    buttonLink: "/candidates/resumes",
    image: ResumeImage,
  },
  {
    title: "Career Coaching Services",
    description:
      "We also provide professional job search and career coaching services. These coaching services are provided at $100 per hour and are also refunded in full if we end up connecting you with an employer directly!",
    bulletPoints: [
      "General Interview Coaching",
      "Job Specific Mock Interview Coaching",
      "Coaching on career paths and opportunities to help you succeed in employment. This can help you to open a world of new opportunities that excite you that you may not have thought of before.",
      "How to get in the door training",
    ],
    buttonText: "Learn more",
    buttonLink: "/about",
    image: CareerImage,
  },
  {
    title: "No Cost to Meet!",
    description:
      "But wait! Does everything have a charge? No of course not! GB Talent Solutions does not want to charge fees to candidates, we want to help people! Let’s meet so we can get to know you and there is often a lot of free coaching and advice coming from this initial meet and greet. We only provide the additional services as sometimes the initial meeting is not enough time to help fully, and more time is required. Reach out to GB Talent Solutions so we can connect today!",
    buttonText: "Contact Us",
    buttonLink: "/contact",
    image: yourImage,
  },
];

const CandidatesSection = () => {
  const theme = useTheme();

  const gradientButtonColor = `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.blue.main} 90%)`;

  return (
    <Box>
      {candidatesServices.map((service, index) => {
        const checkConditionForCard = index % 2 === 0;

        return (
          <Box key={index}>
            <CandidatesCard
              title={service.title}
              description={service.description}
              bulletPoints={service.bulletPoints}
              image={service.image}
              bgColor={
                checkConditionForCard
                  ? theme.palette.common.white
                  : theme.palette.primary.light
              }
              buttonText={service.buttonText}
              buttonLink={service.buttonLink}
              buttonColor={
                checkConditionForCard
                  ? gradientButtonColor
                  : theme.palette.common.white
              }
              buttonTextColor={
                checkConditionForCard
                  ? theme.palette.common.white
                  : theme.palette.secondary.dark
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default CandidatesSection;
