//BlogPage.js
import { useEffect, useState } from "react";

//Section Imports
import Navbar from "../sections/NavBar/Navbar";
import TestimonialsSection from "../sections/Testimonials/TestimonialsSection";
import Footer from "../sections/Footer/Footer";
import HeroBanner from "../sections/HeroBanner/HeroBanner";
import BlogPostsSection from "../sections/Blog/BlogPostSection.js";

//Asset Imports
import blogBannerImage from "../assets/BlogHeroBanner.jpg";

function BlogPosts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/api/posts/")
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
      });
  }, []);

  return (
    <div>
      <Navbar />
      <HeroBanner
        page={"Blog"}
        backgroundImage={blogBannerImage}
        heading="A company's employees are its greatest asset, and your people are your product."
        headingStyle={{ color: "black" }}
        buttonText="Meet Your Next Hire"
        buttonLink="/contact"
      />
      <BlogPostsSection posts={posts} />
      <TestimonialsSection />
      <Footer />
    </div>
  );
}

export default BlogPosts;
